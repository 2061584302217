import { Button } from "antd";
import axios from "axios";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { AUTH_ACTIONS } from "../redux/auth/actions";
import { history } from "../store";

function PricingTable({ subData, isValid = false }) {
  const [paymentId, setPaymentId] = useState("");
  const [paymentError, setPaymentError] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const paymentHandler = async (subId, subName) => {
    setLoading(true);
    const API_URL = process.env.REACT_APP_BASE_URL;
    const orderUrl = `${API_URL}/users/customers/subscription/${subId}`;
    const response = await axios.get(orderUrl, {
      headers: {
        Authorization: `Bearer ${localStorage
          .getItem("accessToken")
          .replaceAll('"', "")}`,
      },
    });
    const { data } = response;
    const options = {
      key: process.env.REACT_APP_RAZOR_PAY_KEY_ID,
      name: subName,
      description: `Payment of subscription with subscription id ${subId}`,
      order_id: data.data.id,
      handler: async (response) => {
        try {
          const url = `${API_URL}/users/customers/subscription/verifyPayment`;
          const captureResponse = await axios.post(
            url,
            {
              paymentId: response.razorpay_payment_id,
              orderId: response.razorpay_order_id,
              signature: response.razorpay_signature,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage
                  .getItem("accessToken")
                  .replaceAll('"', "")}`,
              },
            }
          );
          if (captureResponse.status === 201) {
            setPaymentId(response.razorpay_payment_id);
          }
          setLoading(false);
          dispatch({
            type: AUTH_ACTIONS.GET_CURRENT_ACCOUNT,
            payload: {},
          });
          history.push("/select-class");
        } catch (err) {
          setPaymentError(
            "Transaction declined, please try again later. Any amount deducted from your account will be credited in your account within 7 business days."
          );
          setLoading(false);
        }
      },
      theme: {
        color: "#686CFD",
      },
      modal: {
        ondismiss: function () {
          setPaymentError("Transaction cancelled by user, Please try again.");
          setLoading(false);
        },
      },
    };
    const razorPayClient = new window.Razorpay(options);
    razorPayClient.open();
  };

  return (
    <>
      <div className="error-div">
        {paymentError === "" ? null : (
          <span className="error-msg">{paymentError}</span>
        )}
        {paymentId === "" ? null : (
          <div>
            Please note your payment id{" "}
            <span className="order-id">{paymentId}</span> for future references.
          </div>
        )}
      </div>
      <div className="table centered">
        <div className="row">
          {subData &&
            subData.map((sub, _k) => {
              return (
                <div key={_k} className="columns">
                  <ul className="price">
                    <li className="header">
                      {sub.name}
                      <br />
                      <span className="dollar"> {sub.price} + GST</span>
                    </li>
                    <li>{sub.description}</li>
                    {!isValid ? (
                      <li>
                        <Button
                          onClick={(e) => {
                            e.preventDefault();
                            paymentHandler(sub.id, sub.name);
                          }}
                          variant="contained"
                          className="button-subs"
                          loading={loading}
                        >
                          Buy now
                        </Button>
                      </li>
                    ) : null}
                  </ul>
                </div>
              );
            })}
        </div>
      </div>
    </>
  );
}

export default PricingTable;
