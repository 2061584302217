import { Card, Col, Row, Spin } from "antd";
import {
  CaretRightFilled,
  LoadingOutlined,
  PushpinFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { SUBJECT_ACTIONS } from "../redux/subjects/action";
import { history } from "../store";
import { TOPIC_ACTIONS } from "../redux/topics/action";

const { Meta } = Card;

function Home() {
  const dispatch = useDispatch();

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  const chapterPath = "/:id/chapters";

  const { subjectReducer, topicReducer, authReducer } = useSelector((s) => s);

  useEffect(() => {
    const load = () => {
      if (!authReducer.user.classId) {
        history.push("/select-class");
        return;
      }
      dispatch({
        type: authReducer.user.subscriptionId
          ? SUBJECT_ACTIONS.GET_SUBJECTS
          : TOPIC_ACTIONS.GET_FREE_TOPICS,
        payload: {
          query: {
            perPage: 1000000,
          },
        },
      });
    };
    load();
  }, [dispatch, authReducer.user.subscriptionId, authReducer.user.classId]);

  const handleSubjectSelect = (subjectId) => {
    history.push(chapterPath.replace(":id", subjectId));
  };

  const handleTopicRedirect = (webVrSlug) => {
    window.open(webVrSlug, "_blank");
  };

  return (
    <>
      <div className="layout-content">
        <span
          className="ant-page-header-heading-title"
          style={{ textTransform: "capitalize" }}
        >
          {!authReducer.user.subscriptionId ? "Select Topic" : "Select Subject"}
        </span>
        {subjectReducer.loading ||
        topicReducer.loading ||
        authReducer.loading ? (
          <Spin
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            indicator={antIcon}
          />
        ) : (
          <Row className="mb-24">
            {!authReducer.user.subscriptionId ? (
              <>
                {topicReducer.data.map((item, _k) => (
                  <Col xs={6} key={_k} style={{ padding: "10px 10px 0px 0px" }}>
                    <Card
                      key={_k}
                      hoverable
                      cover={
                        <img alt={item.name} src={item.image[0].preview} />
                      }
                      style={{
                        marginTop: "20px",
                      }}
                      actions={
                        item.webVrSlug
                          ? [
                              <span
                                onClick={() => {
                                  handleTopicRedirect(item.webVrSlug);
                                }}
                              >
                                View Classroom
                                <CaretRightFilled key="setting" />
                              </span>,
                            ]
                          : [
                              <span onClick={() => {}}>
                                Coming Soon
                                <PushpinFilled
                                  key="setting"
                                  style={{ marginLeft: "5px" }}
                                />
                              </span>,
                            ]
                      }
                    >
                      <Meta title={item.name} />
                    </Card>
                  </Col>
                ))}
              </>
            ) : (
              <>
                {subjectReducer.data.map((item, _k) => {
                  return (
                    <Col xs={6} key={_k}>
                      <Card
                        hoverable
                        cover={
                          <img alt={item.name} src={item.image[0].preview} />
                        }
                        style={{
                          marginTop: "20px",
                        }}
                        onClick={() => {
                          handleSubjectSelect(item.id);
                        }}
                      >
                        <Meta title={item.name} />
                      </Card>
                    </Col>
                  );
                })}
              </>
            )}
          </Row>
        )}
      </div>
    </>
  );
}

export default Home;
