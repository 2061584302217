import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../store";
import { CLASS_ACTIONS } from "../redux/classes/action";
import { Button, Row, Select, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

const ClassSelect = () => {
  const dispatch = useDispatch();

  const [selected, setSelected] = useState(null);

  const { authReducer, classReducer } = useSelector((s) => s);

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  useEffect(() => {
    if (authReducer.user.subscriptionId && authReducer.user.classId)
      history.push("/course-content");
  }, [authReducer]);

  useEffect(() => {
    const load = () => {
      dispatch({
        type: CLASS_ACTIONS.GET_CLASSES,
        payload: {
          query: {
            perPage: 1000000,
          },
        },
      });
    };
    load();
  }, [dispatch]);

  const formatDataForSelect = () => {
    return classReducer.data.map((item) => {
      return {
        label: item.name,
        value: item.id,
      };
    });
  };

  const handleSelectedChange = (value) => {
    setSelected(value);
  };

  const handleClassSave = () => {
    dispatch({
      type: CLASS_ACTIONS.SAVE_CLASS,
      payload: {
        body: {
          classId: selected,
          subscriptionId: authReducer.user.subscriptionId,
        },
      },
    });
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      {classReducer.loading ? (
        <Spin
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
          }}
          indicator={antIcon}
        />
      ) : (
        <Row
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Select
            options={formatDataForSelect()}
            placeholder={"Select Subject"}
            onChange={handleSelectedChange}
            style={{ width: "80%" }}
          />
          <Button type="primary" onClick={handleClassSave}>
            Save
          </Button>
        </Row>
      )}
    </div>
  );
};

export default ClassSelect;
