import { Switch, Route, Redirect } from "react-router-dom";

import SignIn from "./pages/SignIn";
import Home from "./pages/Home";
import Topics from "./pages/Topics";
import NotFound from "./pages/NotFound";
import Registration from "./pages/Registration";
import Subscription from "./pages/Subscription";
import Chapters from "./pages/Chapter";
import ClassSelect from "./pages/ClassSelect";

import Main from "./components/layout/Main";

import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";

function App() {
  return (
    <div className="App">
      <Switch>
        <Route path="/auth" exact component={SignIn} />
        <Route path="/registration" exact component={Registration} />
        <Main>
          <Switch>
            <Route exact path="/course-content" component={Home} />
            <Route exact path="/:id/topics" component={Topics} />
            <Route exact path="/:id/chapters" component={Chapters} />
            <Route exact path="/select-class" component={ClassSelect} />
            <Route exact path="/subscriptions" component={Subscription} />
            <Route exact path="/not-found" component={NotFound} />
            <Redirect from="*" to="/not-found" />
          </Switch>
        </Main>
      </Switch>
    </div>
  );
}

export default App;
