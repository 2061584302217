import { all, put, takeEvery } from "redux-saga/effects";
import { ClassesAPIService } from "../../services/classes/classes";
import { doApiCall } from "../helper";
import { CLASS_ACTIONS } from "./action";
import { notification } from "antd";
import { history } from "../../store";
import { AUTH_ACTIONS } from "../auth/actions";

const classesService = new ClassesAPIService();

export function* GET_CLASSES({ payload }) {
  const response = yield doApiCall(
    classesService.getClasses,
    payload,
    CLASS_ACTIONS.SET_STATE
  );

  if (response.success) {
    const { data, pagination } = response;

    yield put({
      type: CLASS_ACTIONS.SET_STATE,
      payload: {
        data,
        pagination,
      },
    });
  }
}

export function* SAVE_CLASS({ payload }) {
  const response = yield doApiCall(
    classesService.saveClass,
    payload,
    CLASS_ACTIONS.SET_STATE
  );

  if (response.success) {
    notification.success({
      message: "Class saved",
      description: "Your preferences are updated.",
    });
    yield put({
      type: AUTH_ACTIONS.GET_CURRENT_ACCOUNT,
      payload: {},
    });
    history.push("/course-content");
  }
}

export function* classesSaga() {
  yield all([
    takeEvery(CLASS_ACTIONS.GET_CLASSES, GET_CLASSES),
    takeEvery(CLASS_ACTIONS.SAVE_CLASS, SAVE_CLASS),
  ]);
}
