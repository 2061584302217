import { Card, Col, Row, Spin } from "antd";
import { ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { CHAPTER_ACTIONS } from "../redux/chapters/action";
import { history } from "../store";

const { Meta } = Card;

function Chapters() {
  let { id } = useParams();
  const dispatch = useDispatch();

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;
  const topicPath = "/:id/topics";

  useEffect(() => {
    const load = () => {
      dispatch({
        type: CHAPTER_ACTIONS.GET_CHAPTERS,
        payload: {
          params: {
            subjectId: id,
          },
          query: {
            perPage: 1000000,
          },
        },
      });
    };

    load();
  }, [dispatch, id]);

  const { chapterReducer } = useSelector((s) => s);

  const handleChapterSelect = (chapterId) => {
    history.push(topicPath.replace(":id", chapterId));
  };

  return (
    <>
      <div className="layout-content">
        <Row style={{ display: "flex", gap: "10px" }}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
          <span
            className="ant-page-header-heading-title"
            style={{ textTransform: "capitalize" }}
          >
            Select Chapter
          </span>
        </Row>
        {chapterReducer.loading ? (
          <Spin
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            indicator={antIcon}
          />
        ) : (
          <Row className="mb-24">
            {chapterReducer.data.map((item, _k) => {
              return (
                <Col xs={6} key={_k} style={{ padding: "10px 10px 0px 0px" }}>
                  <Card
                    key={_k}
                    hoverable
                    cover={<img alt={item.name} src={item.image[0].preview} />}
                    style={{
                      marginTop: "20px",
                    }}
                    onClick={() => {
                      handleChapterSelect(item.id);
                    }}
                  >
                    <Meta title={item.name} />
                  </Card>
                </Col>
              );
            })}
          </Row>
        )}
      </div>
    </>
  );
}

export default Chapters;
