import { Button, Card, Col, Row, Spin } from "antd";
import {
  LoadingOutlined,
  CaretRightFilled,
  PushpinFilled,
  ArrowLeftOutlined,
  ExclamationCircleFilled,
} from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { TOPIC_ACTIONS } from "../redux/topics/action";
import { history } from "../store";

const { Meta } = Card;

function Topics() {
  let { id } = useParams();
  const dispatch = useDispatch();

  const antIcon = <LoadingOutlined style={{ fontSize: 48 }} spin />;

  const load = () => {
    dispatch({
      type: TOPIC_ACTIONS.GET_TOPICS,
      payload: {
        params: {
          chapterId: id,
        },
        query: {
          perPage: 1000000,
        },
      },
    });
  };

  useEffect(() => {
    load();
  }, []);

  const { topicReducer } = useSelector((s) => s);

  const handleTopicRedirect = (webVrSlug) => {
    window.open(webVrSlug, "_blank");
  };

  return (
    <>
      <div className="layout-content">
        <Row style={{ display: "flex", gap: "10px" }}>
          <ArrowLeftOutlined onClick={() => history.goBack()} />
          <span
            className="ant-page-header-heading-title"
            style={{ textTransform: "capitalize" }}
          >
            Select Topic
          </span>
        </Row>
        {topicReducer.loading ? (
          <Spin
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
            }}
            indicator={antIcon}
          />
        ) : (
          <Row className="mb-24">
            {topicReducer.data.map((item, _k) => {
              return (
                <Col xs={6} key={_k} style={{ padding: "10px 10px 0px 0px" }}>
                  <Card
                    key={_k}
                    hoverable
                    cover={<img alt={item.name} src={item.image[0].preview} />}
                    style={{
                      marginTop: "20px",
                    }}
                    actions={
                      item.webVrSlug
                        ? [
                            <span
                              onClick={() => {
                                handleTopicRedirect(item.webVrSlug);
                              }}
                            >
                              View Classroom
                              <CaretRightFilled key="setting" />
                            </span>,
                          ]
                        : [
                            <span onClick={() => {}}>
                              Coming Soon
                              <PushpinFilled
                                key="setting"
                                style={{ marginLeft: "5px" }}
                              />
                            </span>,
                          ]
                    }
                  >
                    <Meta title={item.name} />
                  </Card>
                </Col>
              );
            })}
            {!topicReducer.data.length && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <span
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    marginTop: "200px",
                  }}
                >
                  <ExclamationCircleFilled style={{ fontSize: "50px" }} />
                  <p style={{ textAlign: "center" }}>
                    Oops, We can't find any topic associated with this chapter.{" "}
                    <br /> Please check back later.
                  </p>
                </span>
              </div>
            )}
          </Row>
        )}
      </div>
    </>
  );
}

export default Topics;
