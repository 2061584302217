import axiosClient from "../client";
import { USER_API_ROUTES } from "./constants";
import * as qs from "querystring";

export class ClassesAPIService {
  constructor() {
    this.client = axiosClient;
  }

  getClasses = async ({ query }) => {
    return await this.client.get(
      `${USER_API_ROUTES.GET_CLASSES}?${qs.stringify(query)}`
    );
  };

  saveClass = async ({ body }) => {
    return await this.client.post(USER_API_ROUTES.SAVE_CLASS, body);
  };
}
